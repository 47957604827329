import React from 'react';
import {useRoutes} from 'react-router-dom';

import Home from '../pages/home';
import VHP from '../pages/vhp';
import PageNotFound from '../pages/error';
import '../static/scss/app.scss';

const App = () => {
    const routes = useRoutes([
        {
            path: '/',
            element: <Home/>
        },
        {
            path: '/veteranshistoryproject',
            element: <VHP/>
        },
        {
            path: '/vhp',
            element: <VHP/>
        },
        {
            path: '*',
            element: <PageNotFound/>
        }
    ]);

    return (
        <>
            {routes}
        </>
    );
}

export default App;
