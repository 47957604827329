import React, {useEffect, useRef, useState} from 'react';
import TypeWriter from '../../components/typewriter';
import Seo from '../../components/seo';

const INDEX_LINES = ['The thing is -',
    'I\'m a software engineer...',
    'but I also like',
    'swimming + bird watching + lattes'
    + ' + music + movies + incandescent light + fish tacos'
    + ' + soccer + laughing + silver + hiking + the number 22 ...'];

const Home = () => {
    const boxImage = require(`../../static/images/${process.env.REACT_APP_BOX_IMAGE}`);
    const boxImageHover = require(`../../static/images/${process.env.REACT_APP_BOX_IMAGE_HOVER}`);
    const isMobile = window.innerWidth < 768;

    useEffect(() => {
        TypeWriter({lines: INDEX_LINES});
    }, []);

    const BoxImageRef = useRef();
    const [imageSrc, setImageSrc] = useState(boxImage);
    useEffect(() => {
        BoxImageRef.current.src = imageSrc;
    }, [imageSrc]);

    const handleOnMouseOver = () => {
        if (isMobile) return;
        setImageSrc(boxImageHover);
    };

    const handleOnMouseOut = () => {
        if (isMobile) return;
        setImageSrc(boxImage);
    };

    const handleClick = (e) => {
        if (!isMobile) return;
        setImageSrc(imageSrc === boxImage ? boxImageHover : boxImage);
    };

    return (
        <div id="main" className="home">
            <Seo title={process.env.REACT_APP_TITLE}
                 canonical={process.env.REACT_APP_CANONICAL}
                 description={process.env.REACT_APP_DESCRIPTION}
                 keywords={process.env.REACT_APP_KEYWORDS}/>
            <div className="input-block-level"></div>
            <div id="main-content">
                <div className="container container-xs box-container">
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="box main-box">
                                <img ref={BoxImageRef} alt="" src={require(`../../static/images/${process.env.REACT_APP_BOX_IMAGE}`)}
                                     onMouseOver={handleOnMouseOver}
                                     onMouseOut={handleOnMouseOut}
                                     onClick={handleClick}
                                />
                            </div>
                            <div className="maintext" id="maintext">
                            </div>
                        </div>
                    </div>
                </div>
                <div id="seo">
                    <div className="invisible" id="seo_content">
                        {INDEX_LINES}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
