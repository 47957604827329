import React, {useEffect} from 'react';
import Seo from '../../components/seo'

const VHP = () => {
    const createStyleSheetLink = (href) => {
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = href;
        return link;
    }

    useEffect(() => {
        const head = document.head;
        const body = document.body;
        const headSources = [];

        body.style.background = '#dfdac7';
        body.style.color = '#333'
        body.style.overflowX = 'hidden';

        headSources.push(createStyleSheetLink('//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.4/css/bootstrap.min.css'));
        headSources.forEach((source) => {
            head.appendChild(source);
        });

        return () => {
            headSources.forEach((source) => {
                head.removeChild(source);
            });
        }
    });

    return (
        <div className="vhp">
            <Seo title={process.env.REACT_APP_TITLE_VHP}
                 canonical={process.env.REACT_APP_CANONICAL_VHP}
                 description={process.env.REACT_APP_TITLE_VHP}/>
            <div className="row">
                <div className="col-md-8 col-md-offset-2 header">
                    <h2>
                        <span id="title">
                            <span id="title-text">Dan Golden - Veterans History Project</span>
                        </span>
                    </h2>
                    <p>
                        The Veterans History Project of the American Folklife Center
                        collects, preserves, and makes accessible the personal
                        accounts of American war veterans so that future generations
                        may hear directly from veterans and better understand the
                        realities of war.
                    </p>
                    <p>
                        This is the video of me interviewing my Dad, Dan Golden, for
                        the Veterans History Project. This video interview is also
                        available for viewing in the American Folklife Center's
                        Reading Room located in the Thomas Jefferson Building of the
                        Library of Congress. The Library of Congress is working to
                        have all of the submitted collections viewable online for
                        researchers and families. Due to their limited resources,
                        only 15% of the Veterans History Project collections have
                        been digitized.
                    </p>
                    <h4>
                        <a className="view-collection"
                            href="https://memory.loc.gov/diglib/vhp/bib/loc.natlib.afc2001001.98352"
                            rel="noreferrer"
                            target="_blank"
                            title="Dan Golden Collection"
                            alt="Dan Golden Collection"
                        >Click Here to view Dan's Collection at the Library of Congress</a>
                    </h4>
                </div>
                <div id="bar"></div>
                <div id="video-container">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <h3>Video of Interview</h3>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                    title="Video of Interview"
                                    src="https://www.youtube.com/embed/E-vWP5tMYKE?html5=1&amp;rel=0&amp;showinfo=0&amp;theme=light"
                                    allowFullScreen=""
                                ></iframe>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <h3>Audio Only</h3>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                    title="Audio Only"
                                    width="100%"
                                    height="450"
                                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/252444951&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                    <br/>
                </div>
                <div id="footer"></div>
            </div>
        </div>
    );
};

export default VHP;
